var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-search-page"},[_c('div',{staticClass:"c-search-page__searchbar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],attrs:{"type":"search","autocomplete":"off","placeholder":_vm.$t('search.searchBoxPlaceholder')},domProps:{"value":(_vm.searchText)},on:{"focus":_vm.onSearchBoxFocus,"blur":_vm.onSearchBoxBlur,"change":_vm.throttledSearch,"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}),_c('button',[_c('i',{staticClass:"xp xp-search xp--small",on:{"click":_vm.throttledSearch}})])]),(!_vm.$xp.network.isOnline)?_c('div',[_c('p',{staticClass:"c-search-page__offline"},[_vm._v(" "+_vm._s(_vm.$t("search.offlineMessage"))+" ")])]):(_vm.isLoadingResults)?_c('div',{staticClass:"c-search-page__loading"},[_c('i',{staticClass:"xp xp-spinner xp--large is-spinning"})]):(
      _vm.resultAsMenuEntry &&
      _vm.resultAsMenuEntry.MenuItems &&
      _vm.resultAsMenuEntry.MenuItems.length
    )?_c('MenuEntry',{staticClass:"c-search-page__results",class:{
      'c-search-page__results--user-typing': _vm.isUserTyping,
    },attrs:{"entryData":_vm.resultAsMenuEntry}}):(_vm.searchText)?_c('p',{staticClass:"c-search-page__empty-result",class:{
    'c-search-page__empty-result--user-typing': _vm.isUserTyping,
  }},[_vm._v(" "+_vm._s(_vm.$t("search.emptyResultMessage"))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }